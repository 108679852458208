<template>
    <section class="content">
    <div class="container-fluid">
      
      <div class="row form-horizontal">
        <div class="col-auto">
            <div class="form-group">
                  <select class="form-control" v-model="category">
                    <option
                      v-for="cat in categories"
                      v-bind:key="cat.id"
                      v-bind:value="cat.id"
                    >
                      {{ cat.label }}
                    </option>
                  </select>
            </div>
        </div>
        <div class="col-auto">
          <datepicker v-model="date" />
        </div>
        <div class="col-auto">
            <button class="btn btn-primary" type="button" :disabled="loading"  v-on:click="showLog">Tampilkan</button>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12">
            <div style="max-height:600px;min-height:300px;font-family:'Courier New';overflow: scroll;" class="border p-1" v-html="textContent"></div>
        </div>
      </div>
    </div>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: 'LogFile',
    data() {
        return {
            errors: [],
            categories: [
                {
                    id: 'debug',
                    label: 'debug'
                },
                {
                    id: 'info',
                    label: 'info'
                },
                {
                    id: 'fee',
                    label: 'fee'
                },
                {
                    id: 'tracking',
                    label: 'tracking'
                },
                {
                    id: 'jurnal',
                    label: 'jurnal'
                },
                {
                    id: 'sla',
                    label: 'sla'
                },
                {
                    id: 'delete',
                    label: 'delete'
                },
            ],
            textContent: '',
            loading: false,
            method: '',
            date:'',
            category: '',
            formTitle: 'Tambah Group',
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
    },
    components: {
        datepicker,
    },
    methods: {
        showLog(){
            if(!this.date)return;
            this.loading = true;
            var dt = moment(this.date, "DD/MM/YYYY");
            authFetch('/users/logfile', {
                method: "POST",
                body: 'cat='+this.category+'&dt='+dt.format('YYMMDD'),
            })
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.text();
            })
            .then((text) => {
                this.textContent = text;
                this.loading = false;
            })
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        $('.loading-overlay').removeClass('show');
    }
}
</script>